import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  Button,
  CustomMobileAccordionType,
  Danger,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  EditTableFormatter,
  hexToRgb,
  Hint,
  Typography,
  urlReplace,
  useLoading,
  useTheme,
  includes,
  SortFilters,
  ASC,
  tableSort,
  SortDirection,
  deepClone,
} from "best-common-react";
import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import RouteConstants from "../../constants/RouteConstants";
import {
  PurchaseOpportunityDTO,
  PurchaseOpportunityEntryDTO,
  PurchaseOpportunityFilters,
  PurchaseOpportunityGameDTO,
  PurchaseOpportunityStatusId,
  AdminPurchaseOpportunityEntryDTO,
} from "../../types/PurchaseOpportunity";
import { disablePurchaseOpportunityGame } from "../../api/RequesTixApi";
import { queries } from "../../queries";
import AdminPurchaseOpportunityBulkTransactions from "./AdminPurchaseOpportunityBulkTransactions";

type EntryData = DataTableDataType & {
  user: string;
  firstName: string;
  lastName: string;
  purchaseOpportunityEntryId: number;
  status: string;
  purchaseOpportunityId: number;
  purchaseOpportunityStatusId: PurchaseOpportunityStatusId;
  ballparkEmailAddress: string;
  priority: number;
};

const PurchaseOppGameAccordion: React.FC<React.PropsWithChildren<CustomMobileAccordionType<EntryData>>> = ({
  data,
  index,
  ...rest
}) => {
  const navigate = useNavigate();

  const onEditClick = useCallback(() => {
    navigate(
      urlReplace(RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT, {
        purchaseOpportunityId: data.purchaseOpportunityId,
        purchaseOpportunityEntryId: data.purchaseOpportunityEntryId,
      })
    );
  }, [navigate, data.purchaseOpportunityId, data.purchaseOpportunityEntryId]);

  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <EditTableFormatter value={data.purchaseOpportunityId} row={data} onClick={onEditClick} />
          <span className="bcr-mx-2">{data.user}</span>
          {data.status}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <AttributeHeader>Priority</AttributeHeader>
          <AttributeValue>{data.priority}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>Ballpark Email Address</AttributeHeader>
          <AttributeValue>{data.ballparkEmailAddress}</AttributeValue>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

const convertEntry = (entry: PurchaseOpportunityEntryDTO, opp: PurchaseOpportunityDTO): EntryData => ({
  id: entry.purchaseOpportunityEntryId,
  user: `${entry.user.firstName} ${entry.user.lastName}`,
  firstName: entry.user.firstName,
  lastName: entry.user.lastName,
  purchaseOpportunityId: opp.purchaseOpportunityId,
  purchaseOpportunityEntryId: entry.purchaseOpportunityEntryId,
  status: entry.purchaseOpportunityStatus.description,
  purchaseOpportunityStatusId: entry.purchaseOpportunityStatus.purchaseOpportunityStatusId,
  ballparkEmailAddress: entry.ballparkEmailAddress,
  priority: entry.priority,
});

const filterEntries = (
  entries: PurchaseOpportunityEntryDTO[],
  filters: PurchaseOpportunityFilters
): PurchaseOpportunityEntryDTO[] => {
  return entries.filter((entry: PurchaseOpportunityEntryDTO) => {
    if (filters.statusIds?.length) {
      return filters.statusIds.includes(entry.purchaseOpportunityStatus.purchaseOpportunityStatusId);
    }
    if (filters.freeText?.length) {
      return (
        includes(entry.user.firstName.toLowerCase(), filters.freeText.toLowerCase()) ||
        includes(entry.user.lastName.toLowerCase(), filters.freeText.toLowerCase()) ||
        includes(entry.ballparkEmailAddress.toLowerCase(), filters.freeText.toLowerCase())
      );
    }
    return true;
  });
};

type AdminPurchaseOpportunityGameProps = {
  opp: PurchaseOpportunityDTO;
  game: PurchaseOpportunityGameDTO;
  filters: PurchaseOpportunityFilters;
  reload: () => Promise<any>;
};

const AdminPurchaseOpportunityGame: React.FC<AdminPurchaseOpportunityGameProps> = ({ opp, game, filters, reload }) => {
  const navigate = useNavigate();
  const { Theme } = useTheme();
  const { setLoading } = useLoading();
  const queryClient = useQueryClient();
  const [selectedOpps, setSelectedOpps] = useState<PurchaseOpportunityEntryDTO[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<EntryData>>({
    key: "user",
    direction: ASC,
  });
  const [bulkType, setBulkType] = useState<"charge" | "refund" | undefined>(undefined);

  const disableGameMutation = useMutation({
    mutationFn: async (gameId: number) => {
      await disablePurchaseOpportunityGame(gameId);
    },
    onSuccess: async () => {
      await reload();
      await queryClient.invalidateQueries({
        queryKey: queries.purchaseOpportunity._def,
      });
      setLoading(false);
    },
    onError: (error) => {
      console.error("Failed to disable game:", error);
      setLoading(false);
    },
  });

  const navigateToEntry = useCallback(
    (entryId: number) => {
      navigate(
        urlReplace(RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT, {
          purchaseOpportunityId: opp.purchaseOpportunityId,
          purchaseOpportunityEntryId: entryId,
        })
      );
    },
    [navigate, opp.purchaseOpportunityId]
  );

  const Columns: DataTableColumn<EntryData>[] = useMemo(
    () => [
      {
        name: "",
        key: "purchaseOpportunityEntryId",
        width: 40,
        onClick: (_rowIndex: number, row: EntryData) => {
          navigateToEntry(row.purchaseOpportunityEntryId);
        },
        readonlyFormatter: EditTableFormatter,
        fixed: true,
      },
      {
        key: "user",
        name: "User",
        width: 300,
        sortable: true,
        fixed: true,
      },
      {
        key: "status",
        name: "Status",
        width: 150,
        sortable: true,
      },
      {
        key: "priority",
        name: "Priority",
        width: 75,
        sortable: true,
      },
      {
        key: "ballparkEmailAddress",
        name: "Ballpark Email Address",
        minWidth: 250,
        sortable: true,
      },
    ],
    [navigateToEntry]
  );

  const rowStylesGetter = useCallback(
    (_index: number, value: EntryData): CSSProperties | undefined => {
      switch (value.purchaseOpportunityStatusId) {
        case 2:
          return { background: hexToRgb(Theme.palette.coreColorsColoredSurfaceBlue, 0.4) };
        case 3:
          return { background: hexToRgb(Theme.palette.coreColorsColoredSurfaceYellow, 0.4) };
        case 4:
          return { background: hexToRgb(Theme.palette.coreColorsColoredSurfaceGreen, 0.4) };
        case 5:
          return { background: hexToRgb(Theme.palette.coreColorsColoredSurfaceRed, 0.4) };
        default:
          return {};
      }
    },
    [Theme]
  );

  const disableGame = async () => {
    setLoading(true);
    await disableGameMutation.mutateAsync(game.purchaseOpportunityGameId);
  };

  const rawData: PurchaseOpportunityEntryDTO[] = useMemo(() => {
    return filterEntries(game.entries, filters);
  }, [game, opp, filters]);

  const data: EntryData[] = useMemo(() => {
    return rawData.map((entry: PurchaseOpportunityEntryDTO) => convertEntry(entry, opp));
  }, [rawData, opp]);

  const displayData: EntryData[] = useMemo(() => {
    return deepClone(tableSort(data, sortFilters));
  }, [data, sortFilters]);

  const selectedCount: number = useMemo(
    () => data.filter((entry) => [2, 4].includes(entry.purchaseOpportunityStatusId)).length,
    [data]
  );
  const notSelectedCount: number = useMemo(
    () => data.filter((entry) => 3 === entry.purchaseOpportunityStatusId).length,
    [data]
  );
  const chargedCount: number = useMemo(
    () => data.filter((entry) => 4 === entry.purchaseOpportunityStatusId).length,
    [data]
  );
  const refundedCount: number = useMemo(
    () => data.filter((entry) => 5 === entry.purchaseOpportunityStatusId).length,
    [data]
  );

  const isSelectedReadyForCharge = useMemo(
    () =>
      selectedOpps.some(
        (entry: AdminPurchaseOpportunityEntryDTO) => entry.purchaseOpportunityStatus.purchaseOpportunityStatusId === 2
      ),
    [selectedOpps]
  );
  const isSelectedReadyForRefund = useMemo(
    () =>
      selectedOpps.some(
        (entry: AdminPurchaseOpportunityEntryDTO) => entry.purchaseOpportunityStatus.purchaseOpportunityStatusId === 4
      ),
    [selectedOpps]
  );

  const canBulkCharge = useMemo(
    () => selectedOpps.length > 0 && isSelectedReadyForCharge,
    [selectedOpps, isSelectedReadyForCharge]
  );

  const canBulkRefund = useMemo(
    () => selectedOpps.length > 0 && isSelectedReadyForRefund,
    [selectedOpps, isSelectedReadyForRefund]
  );

  const isSelected = useCallback(
    (rowIndex: number) =>
      selectedOpps.some((item) => item.purchaseOpportunityEntryId === displayData[rowIndex].purchaseOpportunityEntryId),
    [selectedOpps, displayData]
  );

  const onSelectionChange = useCallback(
    (rowIndexes: number[], selected: boolean) => {
      const itemIds: number[] = rowIndexes.map((index) => displayData[index].purchaseOpportunityEntryId);
      const items: PurchaseOpportunityEntryDTO[] = game.entries.filter((item) =>
        itemIds.includes(item.purchaseOpportunityEntryId)
      );
      setSelectedOpps(selected ? [...selectedOpps, ...items] : selectedOpps.filter((item) => !items.includes(item)));
    },
    [selectedOpps, displayData]
  );

  const isSelectionDisabled = useCallback(
    (rowIndex: number) => {
      const statusId: PurchaseOpportunityStatusId | undefined = displayData[rowIndex]?.purchaseOpportunityStatusId;
      if ([1, 3, 5].includes(statusId)) {
        return true;
      } else if (isSelectedReadyForCharge) {
        return statusId !== 2;
      } else if (isSelectedReadyForRefund) {
        return statusId !== 4;
      }
      return false;
    },
    [displayData, isSelectedReadyForCharge, isSelectedReadyForRefund]
  );

  return (
    <>
      <AdminPurchaseOpportunityBulkTransactions
        isOpen={!!bulkType}
        close={() => setBulkType(undefined)}
        isCharge={bulkType === "charge"}
        amount={opp.ticketCost}
        purchaseOpportunities={selectedOpps}
        reload={reload}
      />
      <Accordion accordionKey={`game-${game.gameNumber}`} startOpen animation={false}>
        <Accordion.Header>
          <Typography>
            <div className="d-flex flex-row align-items-center">
              <b>Home Game {game.gameNumber}</b> {!!game?.gamePk ? `Game PK - ${game.gamePk}` : ""}
              <Hint className="bcr-ms-2">Total Entries: {data.length}</Hint>
              <Hint className="bcr-ms-2">Selected Entries: {selectedCount}</Hint>
              <Hint className="bcr-ms-2">Not Selected Entries: {notSelectedCount}</Hint>
              <Hint className="bcr-ms-2">Charged Entries: {chargedCount}</Hint>
              <Hint className="bcr-ms-2">Refunded Entries: {refundedCount}</Hint>
              {!!game.disabled && <Danger className="bcr-ms-2">Disabled</Danger>}
            </div>
          </Typography>
        </Accordion.Header>
        <Accordion.Body>
          <div className="d-flex flex-column w-100">
            <div className="d-flex flex-row bcr-mb-2">
              <Button variant="default" className="bcr-me-2" disabled={game.disabled} onClick={disableGame}>
                Disable Game
              </Button>
              <Button
                variant="secondary"
                className="bcr-me-2"
                disabled={!canBulkCharge}
                onClick={() => setBulkType("charge")}
              >
                Bulk Charge
              </Button>
              <Button
                variant="secondary"
                className="bcr-me-2"
                disabled={!canBulkRefund}
                onClick={() => setBulkType("refund")}
              >
                Bulk Refund
              </Button>
            </div>
            <div style={{ height: "500px" }}>
              <DataTable
                columns={Columns}
                data={displayData}
                statusTextOverride={(selectedCount: number, totalCount: number) =>
                  selectedCount > 0
                    ? selectedCount === totalCount
                      ? `${selectedCount} entries selected`
                      : `${selectedCount} selected of ${totalCount} entries`
                    : `${totalCount} entries`
                }
                rowStylesGetter={rowStylesGetter}
                accordion={PurchaseOppGameAccordion}
                tableHeights={{
                  parentHeight: true,
                }}
                sortColumn={sortFilters.key}
                sortDirection={sortFilters.direction}
                sortFunction={(key: keyof EntryData, direction: SortDirection) => {
                  setSortFilters({ key, direction });
                }}
                rowSelection={{
                  isSelected,
                  onSelectionChange,
                  isSelectionDisabled,
                }}
              />
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </>
  );
};

export default AdminPurchaseOpportunityGame;
