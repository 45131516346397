import React, { useState, useEffect, useMemo } from "react";
import { bulkPurchaseOpportunityTransaction } from "../../api/RequesTixApi";
import { Button, CurrencyInput, Modal, Table, useLoading } from "best-common-react";
import {
  PurchaseOppBulkTransactionDTO,
  PurchaseOpportunityEntryDTO,
  PurchaseOppBulkTransactionErrorDTO,
} from "../../types/PurchaseOpportunity";

type AdminPurchaseOpportunityBulkTransactionsProps = {
  isOpen: boolean;
  close: () => void;
  isCharge: boolean;
  amount: number;
  purchaseOpportunities: PurchaseOpportunityEntryDTO[];
  reload: () => Promise<any>;
};

const AdminPurchaseOpportunityBulkTransactions = ({
  isOpen,
  close,
  isCharge,
  amount: amountProps,
  purchaseOpportunities,
  reload,
}: AdminPurchaseOpportunityBulkTransactionsProps) => {
  const { setLoading, loading } = useLoading();
  const [amount, setAmount] = useState<number>(amountProps);
  const [errors, setErrors] = useState<PurchaseOppBulkTransactionErrorDTO[]>([]);

  const kickOffTx = async () => {
    try {
      setLoading(true);
      const txDTO: PurchaseOppBulkTransactionDTO = {
        purchaseOpportunities: purchaseOpportunities.map((opp) => ({
          purchaseOpportunityEntryId: opp.purchaseOpportunityEntryId,
          userBillingId: opp.userBilling.userBillingId,
        })),
        amount: amount,
        isCharge: isCharge,
      };
      const results: PurchaseOppBulkTransactionErrorDTO[] = await bulkPurchaseOpportunityTransaction(txDTO);
      await reload();
      if (results.length === 0) {
        close();
      } else {
        setErrors(results);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setErrors([]);
    }
  }, [isOpen]);

  const canSave = useMemo(
    () => amount > 0 && purchaseOpportunities.length > 0 && errors.length === 0,
    [amount, purchaseOpportunities, errors]
  );

  return (
    <Modal show={isOpen}>
      <Modal.Header close={close}>Bulk {isCharge ? "Charge" : "Refund"}</Modal.Header>
      <Modal.Body>
        <CurrencyInput
          id="amount"
          label="Charge Amount"
          value={amount}
          onChange={(value: number) => setAmount(value)}
          required
          gutterBottom
          disabled={loading || errors.length > 0}
        />
        {errors.length > 0 && (
          <Table className="bcr-mt-2">
            <Table.Head>
              <Table.Header>Purchase Entry ID</Table.Header>
              <Table.Header>Error</Table.Header>
            </Table.Head>
            <Table.Body>
              {errors.map((error) => (
                <Table.Row key={error.purchaseOpportunityId}>
                  <Table.TdInfo>{error.purchaseOpportunityId}</Table.TdInfo>
                  <Table.Td>{error.error}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="bcr-me-2" variant="primary" onClick={kickOffTx} loading={loading} disabled={!canSave}>
          Charge
        </Button>
        <Button variant="default" onClick={close} loading={loading}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminPurchaseOpportunityBulkTransactions;
